<template>
  <SearchLayout
    class="blog"
    :class="{ pending }"
    :filters="filters"
    :pagination="pagination"
    no-items-text="Мы не нашли подходящие статьи"
  >
    <template #title>
      <PageTitle :note="pagination.total">Статьи</PageTitle>
    </template>
    <template #rubric="{ active }">
      <FilterChips
        name="Рубрики"
        :items="rubrics"
        :active="active.value"
        select
        v-model="filter.rubric"
      />
    </template>
    <template #tags="{ active }">
      <FilterChips
        name="Теги"
        :items="tags"
        :active="active.value"
        v-model="filter.tags"
      />
    </template>

    <template #default>
      <BlogList :posts="posts" />
    </template>

    <template #after>
      <BecomeMentor class="become-section" />
    </template>
  </SearchLayout>
</template>

<script setup>
import { getTags, searchPosts } from '~/api/blog'
import useIsMounted from '~/composables/useIsMounted'
import usePagination from '~/composables/usePagination'

const router = useRouter()
const route = useRoute()

useHead({
  title: 'IT Блог Mentoring.digital - Все о программирование и разработке'
})
useServerSeoMeta({
  description: 'Читать блог по IT-направлениям на Mentoring.digital. Публикуем полезную информацию каждый день',
})

const rubrics = [
  {
    id: 'mentor',
    name: 'От ментора'
  },
  {
    id: 'not_mentor',
    name: 'От редакции'
  }
]

const filter = ref({
  rubric: null,
  tags: []
})

const { data: tags } = await useAsyncData(getTags)

;(query => {
  if (query.rubric) filter.value.rubric = rubrics.find(r => r.id === query.rubric)?.id
  if (query.tags) filter.value.tags = [].concat(query.tags).map(s => +s).filter(s => !isNaN(s))
})(route.query)

const filters = new FilterBuilder()
  .select('rubric', 'Рубрики', filter, rubrics)
  .chips('tags', 'Теги', filter, tags)
  .build()

const isMounted = useIsMounted()

const {
  loading: pending,
  pagination,
  page,
  items: posts,
  load
} = await usePagination(
  (page, perPage) => {
    if (isMounted.value
      || route.query.page
      || filters.some(f => !f.isClear?.value))
      if (isMounted.value) router.replace({ query: { page, ...filter.value } })

    return searchPosts({
      page,
      tags: filter.value.tags.join(','),
      only_mentor: filter.value.rubric === 'mentor' ? 1 : 0,
      only_not_mentor: filter.value.rubric === 'not_mentor' ? 1 : 0,
      per_page: perPage
    })
  },
  ref(6),
  { key: 'blog' }
)

watchDebounced(filter, () => {
  if (pagination.value.current_page === 1) load()
  else page.value = 1
}, { deep: true, debounce: 800 })
</script>

<style scoped lang="scss">

</style>
